import * as React from "react"

import Banner from '../../components/banner'
import Menu from '../../components/menu'
import Footer from '../../components/footer'
import PricingGrid from '../../components/pricing-grid'
import FAQ from '../../components/faq'

function App() {
  return (
    <div>

      <Banner />

      <Menu />

      <PricingGrid title={
        <h2 className="text-5xl font-bold bg-gradient-to-r from-blue-800 via-blue-700 to-blue-600 bg-clip-text text-transparent">
          We offer flexible and obvious pricing built for businesses of all sizes
        </h2>
      } />

      <Footer />

    </div>
  )
}

export default App
